import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="hauterkrankungen" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Chronische Hauterkrankung`}</h1>
      <h3 {...{
        "id": "chronische-hauterkrankung"
      }}>{`Chronische Hauterkrankung`}</h3>
      <p>{`…haben vielfältige Ursachen:`}</p>
      <p>{`Ständige Überlastung des Stoffwechsels, mangelnde Ausleitung über Nieren, unzureichende Entgiftung der Leber und `}<a parentName="p" {...{
          "href": "/beschwerdebilder/chronische-darmerkrankung"
        }}>{`chronische Darmerkrankungen`}</a>{` machen sich häufig als Juckreiz und Ekzem bemerkbar. Eine „Deckelung dieser Ventilfunktion“ mit Kortisonsalben ändert nichts an den Ursachen und führt langfristig zu weiteren Hautschäden. Übermäßige Stressbelastungen können über die vegetative Schiene ebenso wie Darmmilieustörungen über die immunologische Schiene zu Hautproblemen führen.`}</p>
      <p>{`Etliche der schulmedizinisch definierten sog. Hauterkrankungen stellen sich aus ganzheitstherapeutischer Sicht daher ganz anders dar: so kann z.B. die Neurodermitis durchaus auch als immunologisches „Ganzkörperproblem“ gesehen werden – i.d.R. mit gravierenden Darmmilieuproblemen (Fehlbesiedelungen und `}<a parentName="p" {...{
          "href": "/beschwerdebilder/allergien_unvertraeglichkeiten"
        }}>{`Allergien / Unverträglichkeiten`}</a>{`) und Darm-Verpilzungen.`}</p>
      <p>{`Die naturheilkundlichen Therapieansätze zielen ab auf eine Stoffwechselentlastung / `}<a parentName="p" {...{
          "href": "/beschwerdebilder/entgiftung"
        }}>{`Entgiftung`}</a>{`, Behandlung der Ursachen (z.B. Immunschwäche) durch `}<a parentName="p" {...{
          "href": "/beschwerdebilder/immunstaerkung"
        }}>{`Immunstärkung`}</a>{`, `}<a parentName="p" {...{
          "href": "/therapien/darmsanierung"
        }}>{`Darmsanierung`}</a>{`, `}<a parentName="p" {...{
          "href": "/diagnoseverfahren/bioresonanz-testung"
        }}>{`Bioresonanz-Testung`}</a>{` sowie Beruhigung und Regeneration der Haut..`}</p>
      <p>{`Neben Alternativen zur Hydrokortison-Salbe ist für die langfristige Sicherung des Erfolges auch der Einsatz von nicht schädigenden Hautreinigungs- und Pflegemitteln erforderlich, die keine Emulgatoren, Tenside, Paraffin, Konservierungsmittel und andere schädigende Zusätze enthalten dürfen.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      